<template>
  <div>
    <nav style="height: 60px" class="navbar d-flex right">
      <button slot="button" class="navbar_profile dropdown-button">
        <div class="profile_user" v-if="user">
          <span class="username">
            {{ user.nombre }}
          </span>
          <span class="role">
            {{ user.tipo.toUpperCase() }}
          </span>
        </div>
        <div class="profile_thumbnail">{{ acronimo() }}</div>
        <!-- <img class="profile_thumbnail" src="images/profile.jpg" alt=""> -->
      </button>
    </nav>
    <form class="card" v-if="persona" @submit.prevent="guardar">
      <h2 class="card-title">Validar mis datos</h2>
      <h3 class="card-title">Datos personales</h3>
      <div class="cols2">
        <pd-input
          v-model="persona.nombre"
          id="nombre"
          label="Nombre"
          placeholder="Nombre"
          readonly
          classInput="form-control readonly text-bold"
        ></pd-input>
        <pd-input
          v-model="cuilFormateado"
          id="cuil"
          label="Cuil"
          placeholder="Cuil"
          readonly
          classInput="form-control readonly text-bold"
        ></pd-input>
      </div>
      <div class="cols2 my-1">
        <pd-input
          v-model="persona.email"
          id="Email"
          label="Email"
          placeholder="Email"
          type="email"
          :required="$v.persona.email.$dirty && !$v.persona.email.required"
        ></pd-input>
        <pd-input
          v-model="persona.telefono_fijo"
          type="number"
          id="telefono_fijo"
          label="Telefono fijo (opcional)"
          placeholder="Telefono fijo (opcional)"
          inputmode="numeric"
        ></pd-input>
        <div class="form-group">
          <label class="form-label">Celular (sin 0 ni 15)</label>
          <div class="input-group">
            <div class="input-group input-group-prepend">
              <div class="input-group-prepend">
                <span class="input-group-text">0</span>
              </div>
              <input
                id="caracteristica"
                placeholder="Ej:2954"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.caracteristica_celular"
              />
            </div>
            <div class="input-group input-group-append">
              <div class="input-group-prepend">
                <span class="input-group-text">15</span>
              </div>
              <input
                id="numero_celular"
                placeholder="Ej: 555555"
                type="number"
                class="form-control"
                inputmode="numeric"
                v-model="persona.numero_celular"
              />
            </div>
          </div>
        </div>
        <pd-input
          v-model="persona.domicilio"
          type="text"
          id="domicilio"
          label="Domicilio"
          placeholder="Ingrese su domicilio..."
          :required="
            $v.persona.domicilio.$dirty && !$v.persona.domicilio.required
          "
        ></pd-input>
        <pd-input
          v-model="persona.localidad"
          type="text"
          id="localidad"
          label="Localidad"
          placeholder="Ingrese su localidad..."
          :required="
            $v.persona.localidad.$dirty && !$v.persona.localidad.required
          "
        ></pd-input>
        <pd-input
          v-model="persona.provincia"
          type="text"
          id="provincia"
          label="Provincia"
          placeholder="Ingrese su provincia..."
          :required="
            $v.persona.provincia.$dirty && !$v.persona.provincia.required
          "
        ></pd-input>
        <pd-input
          v-model="persona.codigo_postal"
          type="number"
          id="codigo_postal"
          label="Código postal"
          placeholder="Ingrese su código postal..."
          :required="
            $v.persona.codigo_postal.$dirty &&
            !$v.persona.codigo_postal.required
          "
          :numeric="
            $v.persona.codigo_postal.$dirty && !$v.persona.codigo_postal.numeric
          "
          inputmode="numeric"
        ></pd-input>
      </div>
      <h3 class="card-title mb-1 mt-2">Nueva contraseña</h3>
      <div class="cols2 mt-0 mb-1">
        <pd-input
          v-model="password"
          id="password"
          type="password"
          label="Nueva contraseña"
          placeholder="Ingrese su nueva contraseña"
          :required="$v.password.$dirty && !$v.password.required"
        ></pd-input>
        <pd-input
          v-model="confirmPassword"
          id="confirmpassword"
          type="password"
          label="Confirmar contraseña"
          placeholder="Confirme su nueva contraseña"
          :required="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
        >
          <small
            slot="validation"
            class="form-message text-danger"
            v-if="
              $v.password.$dirty &&
              $v.confirmPassword.$dirty &&
              password != confirmPassword
            "
            >Las contraseñas deben coincidir.</small
          >
        </pd-input>
      </div>
      <div>
        <p class="check-validation" :class="classMinLength">
          <icon feather="circle"></icon> La nueva contraseña debe tener 6
          caracteres mínimo.
        </p>
        <p class="check-validation" :class="classUppercase">
          <icon feather="circle"></icon> Debe contener al menos una mayúscula
        </p>
        <p class="check-validation" :class="classHasNumber">
          <icon feather="circle"></icon> Debe contener al menos un número
        </p>
        <p class="check-validation" :class="classSameAs">
          <icon feather="circle"></icon> Las contraseñas deben coincidir
        </p>
      </div>
      <div class="d-flex right">
        <button type="submit" class="btn btn-primary btn-align-right">
          Guardar mis datos
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import Swal from "sweetalert2";
export default {
  name: "ValidarDatos",
  data() {
    return {
      caracteristica: null,
      numero_celular:null,
      persona: null,
      password: null,
      confirmPassword: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    cuilFormateado() {
      if (this.persona) {
        return PersonasService.formatCuil(this.persona.cuil);
      }
      return "";
    },
    sameAs: function () {
      if (this.password && this.confirmPassword) {
        if (this.password === this.confirmPassword) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    classSameAs: function () {
      if (this.sameAs == null) {
        return "text-secondary";
      }
      if (this.sameAs) {
        return "text-success";
      }
      return "text-danger";
    },
    minlength: function () {
      if (this.password != null) {
        if (this.password.length >= 6) {
          return true;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    classMinLength: function () {
      if (this.minlength == null) {
        return "text-secondary";
      }
      if (this.minlength) {
        return "text-success";
      }
      return "text-danger";
    },
    hasUppercase: function () {
      if (this.password != null) {
        return /[A-Z]/.test(this.password);
      } else {
        return null;
      }
    },
    classUppercase: function () {
      if (this.hasUppercase == null) {
        return "text-secondary";
      }
      if (this.hasUppercase) {
        return "text-success";
      }
      return "text-danger";
    },
    hasNumber: function () {
      if (this.password != null) {
        return /\d/.test(this.password);
      } else {
        return null;
      }
    },
    classHasNumber: function () {
      if (this.hasNumber == null) {
        return "text-secondary";
      }
      if (this.hasNumber) {
        return "text-success";
      }
      return "text-danger";
    },
  },
  validations: {
    persona: {
      email: { required },
      telefono_fijo: { numeric },
      // celular: { required, numeric },
      domicilio: { required },
      localidad: { required },
      provincia: { required },
      codigo_postal: { required, numeric },
    },
    password: { required },
    confirmPassword: { required },
  },
  mounted() {
    this.persona = { ...this.user.persona };
  },
  methods: {
    acronimo() {
      let str = this.user.nombre;
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      let acronym = matches.join(""); // JSON
      return acronym;
    },
    guardar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.minlength) {
        Swal.fire(
          "Error",
          `<p>La nueva contraseña debe contener un mínimo de 6 caracteres.</p>`,
          "error"
        );
        return false;
      }
      if (!this.hasUppercase) {
        Swal.fire(
          "Error",
          `<p>La nueva contraseña debe contener al menos una mayúscula.</p>`,
          "error"
        );
        return false;
      }
      if (!this.hasNumber) {
        Swal.fire(
          "Error",
          `<p>La nueva contraseña debe contener al menos un número.</p>`,
          "error"
        );
        return false;
      }
      if (!this.sameAs) {
        Swal.fire("Error", `<p>Las contraseñas deben coincidir.</p>`, "error");
        return false;
      }
      PersonasService.api
        .validarDatos({
          password: this.password,
          confirmPassword: this.confirmPassword,
          ...this.persona,
        })
        .then((response) => {
          if (response.success) {
            Swal.fire("Éxito", `<p>Se ha guardado con éxito</p>`, "success");
            this.$store
              .dispatch("attempt", localStorage.getItem("token"))
              .then(() => {
                this.$router.push({ name: "Inicio" });
              });
          } else {
            Swal.fire("Error", `<p>No se pudo guardar</p>`, "error");
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire(
            "Error",
            `<p>No se pudo guardar</p><p>${error}</p>`,
            "error"
          );
        });
    },
  },
};
</script>

<style scoped>
.check-validation {
  display: flex;
  align-items: flex-start;
  font-size: 0.85rem;
}
.check-validation .feather {
  height: 1rem;
  font-weight: bold;
  stroke-width: 3;
}
</style>