import Axios from 'axios';

export const PersonasService = {
    //API
    api: {
        async find(id) {
            return Axios.get('personas/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('personas')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async getAllForSelect() {
            return Axios.get('personas/for-selects')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async getAllGuardias() {
            return Axios.get('personas/for-guardias')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async getForParteDiario() {
            return Axios.get('personas/for-parte-diario')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(persona) {
            return Axios.post('personas', persona)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, persona) {
            return Axios.put('personas/' + id, persona)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(persona) {
            return Axios.delete('personas/' + persona.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async validarDatos(params) {
            return Axios.post('validar-datos', params)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async cambiarPassword(params) {
            return Axios.post('auth/cambiar-password', params)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async resetPassword(id) {
            return Axios.post('auth/reset-password/'+id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //instances
    persona: {
        nombre: null,
        cuil: null,
        email: null,
        telefono_fijo: null,
        celular: null,
        domicilio: null,
        localidad: null,
        provincia: null,
        codigo_postal: null,
        numero_legajo: null,
        fecha_ingreso: null,
        gerencia_id: null,
        area_id: null,
        sucursal_id: null,
        categoria_id: null,
        obra_social_id: null,
        estado: null,
        area: {},
        obra_social: {},
        categoria: {},
        sucursal: {},
        gerencia: {},
    },


    //Table list
    headers() {
        const header = [{ value: 'Legajo', sortable: true }, { value: 'Persona', sortable: true }, { value: 'Gerencia', sortable: true }, { value: 'Area', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'numero_legajo', class: '', label: 'Legajo' }]
            },
            {
                class: 'important',
                data: [{ value: 'nombre', class: "text-primary" }, { value: 'cuil', class: 'text-small text-secondary', mutator: (cuil) => { return this.formatCuil(cuil) } }]
            },
            {
                class: 'w-100',
                data: [{ value: 'gerencia.descripcion', label: 'Gerencia' }]
            },
            {
                class: 'w-100',
                data: [{ value: 'area.descripcion', label: 'Area' }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "nombre", class: "" },
            { name: "cuil", class: "text-secondary", mutator: (item) => { this.formatCuil(item) } },
        ]
    },
    //MUTATORS
    formatCuil(value) {
        if (!value) return ''
        value = value.toString()
        if (value.length == 11) {
            let values = value.split("");
            values.splice(2, 0, "-");
            values.splice(11, 0, "-");
            return values.join("");
        }
        return value;
    }
}